.main-form {
  form {
    background-color: var(--white-color);
    border-radius: 10px;
    padding: 20px;
  }

  .image-uploader {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  .image-uploader label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .image-uploader .required {
    color: red;
  }

  .file-input {
    display: flex;
    align-items: center;
    // padding:  3px;
    border-radius: 6px;
  }

  .file-input input[type="text"] {
    flex-grow: 1;
    padding: 5px;
    border: none;
    border-radius: 6px;
    border: 1px solid #a7b3bf;
    color: var(--black-color);
    opacity: 0.65;
    height: 33px;
    background-color: var(--white-color);
  }

  .choose-file-button {
    background-color: var(--primary-color);
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    opacity: inherit;
    height: 33px;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .choose-file-button:hover {
    background-color: var(--primary-color);
  }

  .form-heading {
    border-bottom: 1.8px solid #ced4da;
    margin: 10px 10px 30px 10px;

    h5 {
      color: var(--primary-color);
      text-transform: capitalize;
      font-weight: 600;
    }
  }

  label {
    color:  #000000;
    //opacity: 0.65;
    font-weight: 500;
    font-size: 16px;
    font-weight: bold;

  }

  .radio-inner {
    display: flex;
    gap: 30px;
    margin-top: 10px;
  }

  .p-radiobutton .p-radiobutton-box {
    border-radius: 16%;
  }

  .colored-row {
    background-color: rgb(128 128 128 / 8%);
    border-radius: 6px;
    padding: 20px 15px;
    margin-bottom: 1rem;
    margin-top: 1rem !important;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 30px;

    // .p-button {
    //   align-items: center;
    //   justify-content: center;
    //   border-radius: 6px;
    //   color: #101841;

    // }
  }

  .p-button {
    border-radius: 6px;
    margin-right: 0 !important;
        // width: 60px;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 10px 45x;
    font-weight: 500;
  }

  .field {
    margin-bottom: 0.5rem;
  }

 .p-inputtext {
  padding: 10px;
  // height: 50px;
  font-size: 15px;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 10px;

  &:hover, .focus {
    border: 1px solid #0000002e !important;
  }
}

.p-inputtext:focus {
  // border-color: #80bdff; /* Change this color as needed */
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Add a box shadow to highlight focus */
}


  .pi-times-circle {
    font-size: 20px !important;
  }

  .choose-image-none {
    display: none;
  }

  .map-sports {
    gap: 1rem;
    // display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 525px) {
      display: block;
    }

    .map-sport-container {
      border: 1px solid #dfdcdc82;
      margin: 10px;
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02),
        0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
    }

    .remove-button {
      display: flex;
      align-items: center;
      img {
        width: 25px;
      }
    }

    .add-button {
      height: 2rem;
      .pi {
        font-weight: 600;
      }
    }
  }

  .field.grid,
  .formgrid.grid {
    margin: 10px;
  }
}

.tab-buttons {
  display: flex;
  gap: 22px;

  .p-button {
    background-color: #8fcfcc38;
    color: var(--black-color);
    border-radius: 6px;
    border: none;
    padding: 15px 60px;
    border: 2px solid #d9dfdf54;
    font-weight: 500;
    font-size: 15px;

    &:hover {
      border: none !important;
      background-color: #8fcfcc38;
      color: var(--black-color) !important;
      border: 2px solid #d9dfdf54 !important;
    }
  }
  .active-tab {
    border: 2px solid var(--primary-color);
    font-weight: 600;

    &:hover {
      border: 2px solid var(--primary-color) !important;
    }
  }
}

.p-tag {
  font-size: 0.9rem;
}

.p-dialog {
  label {
    color: var(--black-color);
    font-size: 20px;
    font-weight: bold;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 4rem !important;
  }

  .p-dialog-content {
    display: grid;
    place-items: center;
    gap: 20px;
    padding-top: 0 !important;
    padding: 0 1.5rem 1rem 1.5rem !important;
  }

  .p-confirm-dialog-icon {
    font-size: 3.5rem !important;
  }

  .p-inputtextarea {
    margin-top: 0.5rem;
    width: 100%;
  }
}

.p-dialog .p-dialog-footer {
  justify-content: center;
}

.dialog-footer {
  gap: 10px;
}

.secondary-button {
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  background-color: var(--white-color);
  color: var(--primary-color);

  &:hover {
    background-color: #f1d3d1 !important;
    color: var(--primary-color) !important;
  }
}

.error-indicator {
  color: red;
}
.invalid-feedback {
  color: red;
  font-size: 10px;
}


.p-radiobutton .p-radiobutton-box {
  display: none;
}

.p-error {
  color: red;
  display: block;
}

.p-info {
  padding-top: 4px;
  color: green;
  display: block;
}

.field .small {
  display: none;
}

.field .p-error,
.field .p-info {
  display: block;
}

.slot-timings {
  margin-top: 10px;

  .slots-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 576px) {
    .slots-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 768px) {
    .slots-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (min-width: 1200px) {
    .slots-container {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .slot-item {
    border-radius: 6px;
    box-shadow: 0px 3px 5px rgba(66, 64, 64, 0.02),
      0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(167, 136, 136, 0.48) !important;
    padding: 10px;
    background-color: var(--light-color);
    width: 100%;
  }

  .slot-number {
    font-size: 10px;
    font-weight: 600;
  }
  .slot-time {
    font-size: 10px;
  }
}

.p-icon-field {
  width: 100%;
}

.p-input-icon {
  position: absolute;
  right: 10px;
  top: 8px;

  svg {
    width: 16px;
    height: 16px;
    color: var(--primary-color);
  }
}
.form-check-radio{
  display: flex;
}
.form-check-input{
  height: 20px;
  width:20px;
  padding: 20px;
  accent-color: #101841
}
.signature-canvas {
  border: 1px solid #000;
}
.field-signature{
flex-direction: column;
justify-content: center;
}

.main-form {
  align-items: center;
  padding-top: 40px;
  padding: 20px;
  position: relative;
}

.field-signature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.field-signature button {
  margin: 10px;
}

.signature-canvas {
  border: 1px solid #000;
  margin: 10px 0;
}

// .field {
//   margin: 10px 0;
// }

.p-accordion-header-text {
 font-size: 16px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
margin-bottom: 10px;
}

.invalid-feedback {
  color: red;
}

img {
  margin-top: 10px;
  max-width: 100%;
  height: auto;
}

.signature-button{
  display: flex;
  justify-content: center;
  align-items: center;
}
.signature-image{
  width: 450px;
    height: 350px;
}
.s-image{
  width: 450px;
  height: 350px;
   //margin-top: 10px;
  max-width: 100%;
  //height: auto;
  border: 3px solid black;
}

.filter-menu {
  width: 300px;
  padding: 10px 10px;

  .p-submenu-header {
    display: none;
  }

  .filters-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0.75rem 0.5rem;
    color: #343a40;
    background: #ffffff;
    font-weight: 700;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    p {
      margin-bottom: 0;
    }
  }
}
.filters-btn {
  color:white;
  // var(--black-color);
  background-color: #101841;
  //var(--white-color) !important;

  &:hover {
    color:white !important
  }
}
