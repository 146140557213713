.p-datatable-scrollable .p-datatable-thead>tr>th {
    background-color: #6BB1AE;
    font-size: 13px;
    text-align: left;
    /* padding: 0rem 0.6rem; */

}

.p-datatable .p-datatable-tbody>tr>td {
    font-size: 13px;
    /* padding: 0rem 0.6rem; */
    align-items: center;
    height: 50px;
}

.custom-bg {
    background-color: #6BB1AE;
    border: none !important;
    outline: none !important;
    color: #000000;
}

.p-rating .p-rating-icon.pi-star-fill {
    color: gold
}

.custom-white {
    color: #6BB1AE;
    border: 1px solid #6BB1AE !important;
    background-color: white;
}

.custom-bg:hover {
    background-color: #6BB1AE !important;
    border: none !important;
    outline: none !important;
    color: #000000 !important;
}

.custom-bg:focus {
    border: none !important;
    box-shadow: none !important;
}

.w100 {
    width: 100%;
}

.custom-btn-delete {
    background-color: white !important;
    color: red !important;
    border: none;
}

.error {
    color: red !important;
    font-size: 9px;
}

.custom-btn-edit {
    background-color: white !important;
    color: #7DC6F6 !important;
    border: none;
}

.custom-btn-delete:focus {
    box-shadow: none !important;
}

.wide-header {
    width: 30% !important;
}

.custom-btn-edit:focus {
    box-shadow: none !important;
}

.booked {
    background-color: #B88A00;
    color: white;
}

.unbooked {
    background-color: #00722A;
    color: white;
}

.color_yellow {
    background-color: #B88A00;
    padding: 8px;
    height: 20px;
    border: none;
}

.color_blue {
    background-color: #00722A;
    padding: 8px;
    height: 20px;
    border: none;
}

.custom-color {
    color: #6BB1AE;
}

.p-dialog-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #6BB1AE;
    background: #c6e6e5;
    border-color: #c6e6e5;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #6BB1AE;
    border-color: #6BB1AE;
    box-shadow: none;

}

.card.booked:hover {
    cursor: pointer;
}

.card.unbooked {
    cursor: pointer;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #6BB1AE;
    box-shadow: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.p-tabview .p-tabview-panels {
    padding: 1.25rem 0px;
}

.Staric-Custom {
    color: red;
}

.p-inputtext:enabled:focus {
    /* border: 1px solid #ced4da; */
    box-shadow: none;
}

.p-inputtext:enabled:hover {
    border: 1px solid #ced4da;
    box-shadow: none;
}

.mobile-show {
    display: none;
}

.custom-p-button {
    color: whitesmoke;
    background: #FFD54F;
    border: 1px solid #FFD54F;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px !important;
    max-width: 100px !important;
}

@media only screen and (max-width: 768px) {
    .mobile-show {
        display: block;
    }
}

.p-button-text {
    color: var(--black);
}